<template>
  <nice-tooltip
  >
    <template #content>
      {{
        $t('integrations.extension-propgen.generic.documents.generatingInformation', {
          duration: $t('integrations.extension-propgen.reel.estimatedDuration'),
        })
      }}

      <ul class="mt-2">
        <li v-for="context in runningVideoOrders">
          {{
            $t('integrations.extension-propgen.generic.documents.generatingVideoInformation', {name: getBrokerName(context.userId), when: formatFromNow(context.createdTimestamp)})
          }}
        </li>
      </ul>
    </template>
    <div
      class="inline-flex flex-nowrap shrink-0 items-center space-x-2 whitespace-nowrap"
      v-if="runningVideoOrders?.length"
    >
      <spinner />
      <GradientFlashText>
        {{ $t("integrations.extension-propgen.generic.documents.generatingVideosCount", runningVideoOrders.length) }}
      </GradientFlashText>
    </div>
  </nice-tooltip>
</template>
<script setup lang="ts">
import GradientFlashText from "@/components/integrations/propgen/GradientFlashText.vue";
import { IntegrationOrderPropgen} from "@/integrations/types/integration-propgen";
import {NiceTooltip} from "@nice-ui";
import { defineProps } from "vue";
import {useBroker} from "@/composables/use-broker";
import { timestampToDate} from "@/utils/formatters/date";
import moment from "moment";
const { getBrokerById} = useBroker()

const {runningVideoOrders = []} = defineProps<{ runningVideoOrders: IntegrationOrderPropgen[] }>();

const getBrokerName = (brokerIdString: string) => {
  const brokerId = parseInt(brokerIdString);
  const broker = getBrokerById(brokerId);
  return broker?.name ?? "Unbekannt";
}

const formatFromNow = (timestamp: number) => {
  const date = timestampToDate(timestamp);
  return moment(date).fromNow();
}

</script>
